<template>
  <div v-if="!isFetching" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Tabela de Papeis</h4>
          <h5 class="title">
            Aqui só será possivel associar Papeis a usuarios que não sejam
            PENSIONISTA OU SERVIDOR e que não tenham papeis já associados.
          </h5>
        </md-card-header>

        <md-card-content>
          <div class="md-size-100 text-left">
            <!--<md-button
              class="md-raised md-success mt-4"
              @click="
                {
                  (classicModal = true),
                    (modalTitle = 'Nova Papel'),
                    (buttonModal = 'criar'),
                    (formCriar = true);
                }
              "
            >
              Nova Papel
            </md-button> -->
          </div>
          <p></p>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por pagina</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Pesquisar"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id Papel" md-sort-by="idPapel">{{
                item.idPapel
              }}</md-table-cell>
              <md-table-cell md-label="Nome Papel" md-sort-by="nomePapel">{{
                item.nomePapel
              }}</md-table-cell>
              <md-table-cell
                md-label="Descrição Papel"
                md-sort-by="descricaoPapel"
                >{{ item.descricaoPapel }}</md-table-cell
              >

              <md-table-cell md-label="Ações">
                <md-button
                  class="md-just-icon md-simple"
                  :class="checkAssingmentStatus(item)"
                  @click="handleAssingment(item)"
                >
                  <md-tooltip md-direction="top">Associar</md-tooltip>
                  <md-icon>assignment_ind</md-icon>
                </md-button>

                <md-button
                  class="md-just-icon md-success md-simple"
                  @click.native="handleEdit(item)"
                >
                  <md-tooltip md-direction="top">Editar</md-tooltip>
                  <md-icon>edit</md-icon>
                </md-button>
                <!--<md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-icon>delete</md-icon>
                </md-button>-->
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.nomePapel"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>

      <template>
        <modal v-if="classicModal" @close="classicModalHide">
          <template slot="header">
            <h4 class="modal-title">{{ modalTitle }}</h4>
            <md-button
              class="md-simple md-just-icon md-round modal-default-button"
              @click="classicModalHide"
            >
              <md-icon>clear</md-icon>
            </md-button>
          </template>

          <template slot="body">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="
                  formCriar === true
                    ? handleSubmit(submit)
                    : handleSubmit(editRow)
                "
              >
                <!--  <form @submit.prevent="handleSubmit(submit)"> -->
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <ValidationProvider
                      name="form.nomePapel"
                      rules="required|min:3|max:100"
                      v-slot="{ passed, failed, errors }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed }
                        ]"
                      >
                        <label>Nome Papel</label>
                        <md-input
                          v-model="form.nomePapel"
                          type="text"
                        ></md-input>
                      </md-field>
                      <span
                        id="error"
                        class="block text-red-600 text-xs absolute bottom-0 left-0"
                        v-if="errors[0]"
                        >{{ errors[0] }}
                      </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <ValidationProvider
                      name="form.descricaoPapel"
                      rules="required|min:3|max:100"
                      v-slot="{ passed, failed, errors }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed }
                        ]"
                      >
                        <label>Descrição Papel</label>
                        <md-textarea
                          v-model="form.descricaoPapel"
                        ></md-textarea>
                      </md-field>
                      <span
                        id="error"
                        class="block text-red-600 text-xs absolute bottom-0 left-0"
                        v-if="errors[0]"
                        >{{ errors[0] }}
                      </span>
                    </ValidationProvider>
                  </div>
                  <div class="md-layout-item md-size-100 text-right">
                    <md-button type="submit" class="md-raised md-success mt-4">
                      {{ buttonModal }}
                    </md-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
          <template slot="footer"> </template>
        </modal>
      </template>
    </div>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { Modal } from "@/components";
import { store } from "../../../store";
import { mapState, mapActions } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { Pagination } from "@/components";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";

import Fuse from "fuse.js";
import Swal from "sweetalert2";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    Pagination,
    Modal
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile,
    reload: function() {
      this.getPapelAll();
      return this.queriedData;
    },

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      mensagem: "",
      idPapel: "",
      formCriar: true,
      buttonModal: "criar",
      modalTitle: "Nova Papel",
      isFetching: true,
      form: {
        descricaoPapel: "",
        nomePapel: ""
      },
      classicModal: false,
      papel: "",
      currentSort: "nomePapel",
      currentSortOrder: "desc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      footerTable: ["Id Papel", "Nome Papel", "Descrição Papel", "Ações"],
      searchQuery: "",
      propsToSearch: ["nomePapel", "descricaoPapel"],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  beforeMount() {
    this.getPapel();
    this.getPapelAll();
  },
  methods: {
    /*checkAssingmentDisabled(item) {
      if (item.nomePapel === "SERVIDOR" || item.nomePapel === "PENSIONISTA") {
        return "disabled";
      } else {
        return null;
      }
    },*/
    checkAssingmentStatus(item) {
      //if (item.nomePapel === "SERVIDOR" || item.nomePapel === "PENSIONISTA") {
      //  return "";
      //} else {
      return "md-info";
      //}
    },
    classicModalHide: function() {
      this.classicModal = false;
      this.form.descricaoPapel = "";
      this.form.nomePapel = "";
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    ...mapActions("papel", [
      "ActionGetPapelAll",
      "ActionPOSTPapel",
      "ActionDELETEPapel",
      "ActionPUTPapel",
      "ActionSetPapel"
    ]),
    async getPapelAll() {
      try {
        let papel = this.papel;
        let response = await this.ActionGetPapelAll({ papel });
        this.tableData = response.body;
        this.isFetching = false;
        return response.body;
      } catch (err) {
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
        //console.log(err);
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleAssingment(item) {
      let nomePapelSelected = item.nomePapel;
      let id = item.idPapel;
      this.ActionSetPapel({ nomePapelSelected });
      window._Vue.$router
        .push({ path: `papel/associacao/${id}` })
        .catch(err => {});
    },
    handleEdit(item) {
      this.formCriar = false;
      this.buttonModal = "Atualizar";
      this.modalTitle = "Editar Papel";
      this.idPapel = item.idPapel;
      this.form.nomePapel = item.nomePapel;
      this.form.descricaoPapel = item.descricaoPapel;
      this.classicModal = true;
    },
    async editRow() {
      try {
        let id = this.idPapel;
        const newForm = {
          idPapel: this.idPapel,
          nomePapel: this.form.nomePapel,
          descricaoPapel: this.form.descricaoPapel
        };
        let papel = this.papel;
        //console.log(newForm);
        let response = await this.ActionPUTPapel({ id, papel, newForm });
        if (response.status === 200) {
          //console.log(response.body.mensagem);
          this.classicModalHide();
          this.reload;
          this.idPapel = "";
          Swal.fire({
            icon: "success",
            //title: "Atualizado!",
            text: `${response.body.mensagem}`,
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
      } catch (err) {
        this.idPapel = "";
        this.classicModalHide();
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    handleDelete(item) {
      const { value: PapelNome } = Swal.fire({
        title: "Você tem certeza ?",
        text: `Se você excluir um Papel todas os Servidor Publicos, Pensionistas e demais dados referente a este Papel serão excluidos ! 
        Isto será irreversível! Favor digitar o Nome do Papel de forma idêntica.`,
        showCancelButton: true,
        input: "text",
        inputValue: "Digite o Nome do Papel.",
        inputValidator: value => {
          if (!value) {
            return "Você precisa digitar o Nome da Papel!";
          }
          if (!(value === item.nomePapel)) {
            return "O Nome do Papel não existe ou não confere!";
          }
        },
        customClass: {
          confirmButton: "md-button md-success btn-fill",
          cancelButton: "md-button md-danger btn-fill"
        },
        confirmButtonText: "Sim, excluir!",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    async deleteRow(item) {
      try {
        let id = item.idPapel;
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionDELETEPapel({ id, papel, form });
        if (response.status === 200) {
          let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.idPapel === item.idPapel
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          this.classicModalHide();
          this.reload;
          Swal.fire({
            icon: "success",
            title: "Excluido!",
            text: `${item.nomePapel} ${response.body.mensagem}`,
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
      } catch (err) {
        this.classicModalHide();
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async submit() {
      try {
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionPOSTPapel({ papel, form });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          Swal.fire({
            //title: "Sucesso",
            icon: "success",
            text: this.mensagem,
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
        this.classicModalHide();
        this.reload;
      } catch (err) {
        this.classicModalHide();
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    /*this.fuseSearch = new Fuse(this.tableData, {
      keys: ["nomePapel", "descricaoPapel"],
      threshold: 0.3
    });*/
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        this.fuseSearch = new Fuse(result, {
          keys: ["nomePapel", "descricaoPapel"],
          threshold: 0.2
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.fuseSearch = null;
      }
      this.searchedData = result;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-simple,
.md-button.md-simple:hover,
.md-button.md-simple:focus,
.md-button.md-simple:active,
.md-button.md-simple.active,
.md-button.md-simple:active:focus,
.md-button.md-simple:active:hover,
.md-button.md-simple.active:focus,
.md-button.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
</style>
